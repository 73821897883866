






























































import Base from '@/mixins/Base.vue';
import { IObjectCategory, IObjectCategoryResponse } from '@/interfaces/objectcategory';

const component = Base.extend({
    data() {
        return {

            active: false,
            category: {} as IObjectCategory,
        };
    },
    mounted() {
        this.active = true;
    },
    methods: {
        save(): void {
            this.post<IObjectCategoryResponse>('object-categories', {
                name: this.category.name,
            }).then(({ data }) => {
                this.$router.push({ name: 'categories.edit', params: { id: data.data.id.toString() } });
            });
        },
    },
});

export default component;
